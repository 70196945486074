<template>
  <div class="map">
    <!-- 可视化文本盒子 -->
    <div class="map_title">
      <div class="map_v">
        <h1>数据可视分布图</h1>
        <span>Visual distribution map</span>
      </div>
      <div class="center_box">
        <div class="info_left">
          <ul>
            <li v-for="(item,index) in infoLeft" :key="index">
              <span class="info_span">{{item.area}}</span>
              <h1 class="info_h1">{{item.value}}</h1>
            </li>
          </ul>
        </div>
        <!-- 地图外图形盒子 -->
        <div class="map_box">
          <!-- 地图盒子 -->
          <div id="chinaMap" class="china_map">
            <Map />
          </div>
          <div class="map_box_l mapicon">
            <i class="el-icon-caret-left" style="font-size:24px;color:#0F38FF"></i>
          </div>
          <!-- 旋转图形盒子 -->
          <div class="map_c">
            <div class="map_x1">
              <div class="map_x2"></div>
            </div>
          </div>
          <div class="map_box_r mapicon">
            <i class="el-icon-caret-right" style="font-size:24px;color:#0F38FF"></i>
          </div>
        </div>
        <div class="info_right">
          <ul>
            <li v-for="(item,index) in infoRight" :key="index">
              <span class="info_span">{{item.area}}</span>
              <h1 class="info_h1">{{item.value}}</h1>
            </li>
          </ul>
        </div>
      </div>
      <div class="map_f"></div>
    </div>
  </div>
</template>

<script>
import Map from './maps.vue'
export default {
  data () {
    return {
      infoLeft: [
        {
          area: '江苏省',
          value: '1886家',
        },
        {
          area: '江苏省',
          value: '1886家',
        },
        {
          area: '江苏省',
          value: '1886家',
        },
        {
          area: '江苏省',
          value: '1886家',
        },
        {
          area: '江苏省',
          value: '1886家',
        },
        {
          area: '江苏省',
          value: '1886家',
        },
      ],
      infoRight: [
        {
          area: '江苏省',
          value: '1886家',
        },
        {
          area: '江苏省',
          value: '1886家',
        },
        {
          area: '江苏省',
          value: '1886家',
        },
        {
          area: '江苏省',
          value: '1886家',
        },
        {
          area: '江苏省',
          value: '1886家',
        },
        {
          area: '江苏省',
          value: '1886家',
        },
      ],
    }
  },
  components: {
    Map,
  },
}
</script>

<style scoped>
.map {
  background: #fff;
}
.map_title {
  width: 100%;
  min-height: 850px;
  background: url('../../assets/images/map_background.png') center;
  background-size: 100%;
}
.map_v {
  text-align: center;
  height: 62px;
  background: url('../../assets/images/map_background_top.png') no-repeat center;
  background-size: 110%;
  overflow: hidden;
}
.map_f {
  height: 62px;
  background: url('../../assets/images/map_background_footer.png') no-repeat
    center;
  background-size: 110%;
  margin-top: 50px;
}
.map_v h1 {
  margin-top: 10px;
  font-size: 24px;
  font-family: pangmen;
  font-weight: 400;
  color: #000000;
}
.map_v span {
  font-size: 10px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #000000;
}
.info_span {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #9698af;
}
.info_h1 {
  font-size: 32px;
  font-family: pangmen;
  font-weight: 400;
  color: #36374a;
  margin-bottom: 40px;
}
.info_left {
  margin-left: 40px;
  /* position: absolute;
  left: 40px;
  top: 120px; */
}
.info_right {
  margin-right: 40px;
  /* position: absolute;
  right: 40px;
  top: 120px; */
}
.center_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  position: relative;
}
.map_box {
  width: 70%;
  height: 650px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.mapicon {
  width: 100px;
  height: 100%;
  /* background: tan; */
  display: flex;
  align-items: center;
}
.mapicon i {
  display: block;
  margin: 0 auto;
  cursor: pointer;
}
.map_box_l {
  background: url('../../assets/images/map_click_l.png') no-repeat center;
  background-size: 100%;
}
.map_box_r {
  background: url('../../assets/images/map_click_r.png') no-repeat center;
  background-size: 100%;
}
/* 逆时针旋转 */
.map_c {
  width: 60%;
  height: 100%;
  background: url('../../assets/images/map_c_1.png') no-repeat center;
  background-size: 115%;
  border-radius: 50%;
  animation: rotate1 5s linear infinite;
  overflow: hidden;
}
/* 顺时针旋转 */
.map_x1 {
  width: 100%;
  height: 100%;
  background: url('../../assets/images/map_c_2.png') no-repeat center;
  background-size: 95%;
  border-radius: 50%;
  animation: rotate 3s linear infinite;
}
.map_x2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: url('../../assets/images/map_c_3.png') no-repeat center;
  background-size: 110%;
  animation: rotate2 5s linear infinite;
}
/* 360°旋转动画 */
/* 需要兼容的自行补入噢 */
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate1 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes rotate2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.china_map {
  width: 80%;
  height: 100%;
  position: absolute;
  left: 0;
  top: -10%;
  z-index: 999;
}
</style>