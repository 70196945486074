<template>
  <div class="index">
    <div class="header">
      <!-- <Header title="数据面板" index="首页" beforeTitle="数据面板" /> -->
      <div class="shuj">
        <div class="header_shuj">
          <h1 @click="shujClick()" :class="indexEcharts == true?'index_grey':'index_black'">数据面板</h1>
          <h1 @click="mapClick()" :class="indexMap == true?'map_grey':'map_black'">地域分布</h1>
        </div>
        <div class="indexicon">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>数据面板</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <transition name="index" mode="out-in">
        <Index v-if="indexEcharts" />
        <Map v-if="indexMap" />
      </transition>
    </div>
  </div>
</template>

<script>
import Index from '../components/indexEcharts/index.vue'
import Map from '../components/map/index.vue'
export default {
  name: "index",
  data () {
    return {
      indexEcharts: true,
      indexMap: false,
    };
  },
  components: {
    Index,
    Map,
  },
  beforeCreate () {
    // console.log("页面加载前");
  },
  created () {
    // console.log("页面加载后");
  },
  methods: {
    shujClick () {
      this.indexEcharts = true;
      this.indexMap = false
    },
    mapClick () {
      this.indexEcharts = false;
      this.indexMap = true
    },
  },
  mounted () {
  },
};
</script>

<style scoped>
.shuj {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .shuj h1 {
  font-size: 20px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  line-height: 14px;
}
.header_shuj h1 {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
.index_grey,
.map_grey {
  color: #000000;
}
.index_black,
.map_black {
  color: #a6abc7;
}
</style>
