<template>
  <div class="index">
    <div class="header">
      <!-- <Index /> -->
      <div class="card">
        <el-row :gutter="12">
          <el-col>
            <el-card class="cardText" body-style="padding:0;margin:10% 0 0 8%" shadow="hover">
              <span>
                <!-- {{ item.title }} -->
                本月合同
                <el-dropdown trigger="click" @command="monthClick">
                  <span
                    style="font-weight: bold;margin-left: 20px;cursor: pointer;"
                    class="el-dropdown-link"
                  >
                    {{ newDate }}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="yearNow + '-' + 1">{{ yearNow + '-' + 1 }}</el-dropdown-item>
                    <el-dropdown-item :command="yearNow + '-' + 2">{{ yearNow + '-' + 2 }}</el-dropdown-item>
                    <el-dropdown-item :command="yearNow + '-' + 3">{{ yearNow + '-' + 3 }}</el-dropdown-item>
                    <el-dropdown-item :command="yearNow + '-' + 4">{{ yearNow + '-' + 4 }}</el-dropdown-item>
                    <el-dropdown-item :command="yearNow + '-' + 5">{{ yearNow + '-' + 5 }}</el-dropdown-item>
                    <el-dropdown-item :command="yearNow + '-' + 6">{{ yearNow + '-' + 6 }}</el-dropdown-item>
                    <el-dropdown-item :command="yearNow + '-' + 7">{{ yearNow + '-' + 7 }}</el-dropdown-item>
                    <el-dropdown-item :command="yearNow + '-' + 8">{{ yearNow + '-' + 8 }}</el-dropdown-item>
                    <el-dropdown-item :command="yearNow + '-' + 9">{{ yearNow + '-' + 9 }}</el-dropdown-item>
                    <el-dropdown-item :command="yearNow + '-' + 10">{{ yearNow + '-' + 10 }}</el-dropdown-item>
                    <el-dropdown-item :command="yearNow + '-' + 11">{{ yearNow + '-' + 11 }}</el-dropdown-item>
                    <el-dropdown-item :command="yearNow + '-' + 12">{{ yearNow + '-' + 12 }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
              <h1>
                <countTo
                  style="
                    margin-top: 15px;
                    font-size: 31px;
                    font-family: pangmen;
                    font-weight: 400;
                    color: #141414;
                  "
                  :startVal="0"
                  :endVal="contractRunCount"
                  :duration="2000"
                  :decimais="2"
                ></countTo>
                <span style="margin-left: 2%">份</span>
              </h1>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="12">
          <el-col>
            <el-card body-style="padding:0;margin:10% 0 0 8%" shadow="hover">
              <span>
                <!-- {{ item.title }} -->
                总计合同
              </span>
              <h1>
                <countTo
                  style="
                    margin-top: 15px;
                    font-size: 31px;
                    font-family: pangmen;
                    font-weight: 400;
                    text-align: left;
                    color: #141414;
                  "
                  :startVal="0"
                  :endVal="contractConut"
                  :duration="2000"
                ></countTo>
                <span style="margin-left: 2%">份</span>
              </h1>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="12">
          <el-col>
            <el-card body-style="padding:0;margin:10% 0 0 8%" shadow="hover">
              <span>
                <!-- {{ item.title }} -->
                总计合作客户
              </span>
              <h1>
                <countTo
                  style="
                    margin-top: 15px;
                    font-size: 31px;
                    font-family: pangmen;
                    font-weight: 400;
                    text-align: left;
                    color: #141414;
                  "
                  :startVal="0"
                  :endVal="coopCount"
                  :duration="2000"
                ></countTo>
                <span style="margin-left: 2%">家</span>
              </h1>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="12">
          <el-col>
            <el-card body-style="padding:0;margin:10% 0 0 8%" shadow="hover">
              <span>
                <!-- {{ item.title }} -->
                总计意向客户
              </span>
              <h1>
                <countTo
                  style="
                    margin-top: 15px;
                    font-size: 31px;
                    font-family: pangmen;
                    font-weight: 400;
                    text-align: left;
                    color: #141414;
                  "
                  :startVal="0"
                  :endVal="intCount"
                  :duration="2000"
                ></countTo>
                <span style="margin-left: 2%">家</span>
              </h1>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="12">
          <el-col>
            <el-card body-style="padding:0;margin:10% 0 0 8%" shadow="hover">
              <span>
                <!-- {{ item.title }} -->
                进行中项目
              </span>
              <h1>
                <countTo
                  style="
                    margin-top: 15px;
                    font-size: 31px;
                    font-family: pangmen;
                    font-weight: 400;
                    text-align: left;
                    color: #0453FF;
                  "
                  :startVal="0"
                  :endVal="projectConut"
                  :duration="2000"
                ></countTo>
                <span style="margin-left: 2%">个</span>
              </h1>
            </el-card>
          </el-col>
        </el-row>
        <!-- 自定义编辑面板 -->
        <!-- <div class="customs">
          <el-row class>
            <el-col :span="15" class>
              <el-dropdown trigger="click">
                <span class="custom" style="display: block">
                  <h1>自定义</h1>
                  <h2>customize</h2>
                  <div class="h3">
                    <p>编辑面板</p>
                    <i class="el-icon-arrow-righ"></i>
                  </div>
                  <div class="hidden">
                    <div class="skyblue"></div>
                    <div class="blue"></div>
                  </div>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-checkbox-group v-model="checkList" style="width: 180px">
                    <el-checkbox
                      checked
                      v-model="checkOne"
                      label="checkOne"
                      @change="checkOnes"
                    >客户合同统计"</el-checkbox>
                    <el-checkbox
                      checked
                      v-model="checkTwo"
                      label="checkTwo"
                      @change="checkTwos"
                    >总项目数据</el-checkbox>
                    <el-checkbox
                      checked
                      v-model="checkThree"
                      label="checkThree"
                      @change="checkThrees"
                    >近三年项目数据</el-checkbox>
                    <el-checkbox
                      checked
                      v-model="checkFour"
                      label="checkFour"
                      @change="checkFours"
                    >财务月收款</el-checkbox>
                    <el-checkbox label="其他数据 A">其他数据 A</el-checkbox>
                    <el-checkbox label="其他数据 B">其他数据 B</el-checkbox>
                  </el-checkbox-group>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </el-row>
        </div>-->
      </div>
    </div>

    <!-- echarts图表 -->
    <div class="allech">
      <!-- 柱状图 -->
      <div class="mainbar" v-show="checkOne">
        <div class="bartext">
          <div class="date_select">
            <el-date-picker
              prefix-icon="el-icon-caret-bottom"
              class="dateBar"
              v-model="dateBar"
              type="date"
              :placeholder="dateToday1"
              @change="dateSelect1"
              :clear-icon="false"
            ></el-date-picker>
          </div>
        </div>
        <div id="mainbar" :style="`zoom:${zoom};transform:scale(${1});transform-origin:0 0`"></div>
      </div>
      <!-- 饼图 -->
      <div class="mainpie" v-show="checkTwo">
        <div id="mainpie" :style="`zoom:${zoom};transform:scale(${1});transform-origin:0 0`"></div>
      </div>
      <!-- 折线图 -->
      <div class="mainline" v-show="checkThree">
        <div class="linetext">
          <div class="date_select">
            <el-date-picker
              prefix-icon="el-icon-caret-bottom"
              class="dateBar"
              v-model="dateLine"
              type="date"
              :placeholder="dateToday2"
              @change="dateSelect2"
              :clear-icon="false"
            ></el-date-picker>
          </div>
        </div>
        <h1>近三年数据项目</h1>
        <div id="mainline" :style="`zoom:${zoom};transform:scale(${1});transform-origin:0 0`"></div>
      </div>
      <!-- 渐变色圆弧折线图 -->
      <div class="maincolorline" v-show="checkFour">
        <div class="colorlinetext">
          <div class="date_select">
            <el-date-picker
              prefix-icon="el-icon-caret-bottom"
              :placeholder="dateToday3"
              :clear-icon="false"
              @change="dateSelect3"
              class="dateBar"
              v-model="dateColorBar"
              type="year"
              value-format="yyyy"
            ></el-date-picker>
          </div>
        </div>
        <h1>合同数据统计</h1>
        <div id="maincolorline" :style="`zoom:${zoom};transform:scale(${1});transform-origin:0 0`"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import Index from '../components/indexEcharts/index.vue'
// 引入图表自适应文件
import { EleResize } from "../../assets/js/esresize";
// 引入数字滚动插件
import countTo from "vue-count-to";
export default {
  name: "index",
  data () {
    return {
      barData: [],
      pieData: [],
      three: [],
      con_year_conut: [],
      zoom: 1,
      dateBar: '',//柱状图日期model
      dateLine: '',//折线图日期model
      dateColorBar: '',//蓝色柱状图日期model
      dateToday1: '',//今天日期
      dateToday2: '',//今天日期
      dateToday3: '',//今天日期
      newDay: '',
      yearNow: '',
      newDate: '',
      contractRunCount: 0,
      coopCount: 0,
      intCount: 0,
      contractConut: 0,
      projectConut: 0,
      show: false,
      checkOne: true,
      checkTwo: true,
      checkThree: true,
      checkFour: true,
      checkList: [],
    };
  },
  components: {
    countTo,
    // Index,
  },
  beforeCreate () {
    // console.log("页面加载前");
  },
  created () {
    this.getToday()
    this.$nextTick(() => {
      this.customCoop()
      this.contractNum()
      this.projectRuning()
      this.contractMonth()
      this.customInt()
      this.getBardata()
      this.getPieData()
      this.getThreeData()
      if (this.dateToday3) {
        this.axios.get('/api/contract/contract_year_count', {
          params: {
            year: this.dateToday3
          }
        }).then((res) => {
          this.con_year_conut = res.data
          this.colorLine()
        })
      }
    })
  },
  methods: {
    getBardata () {
      if (this.dateToday1) {
        this.axios.get('/api/company/custom_count_chart', {
          params: {
            type: 1,
            select_time: this.dateToday1
          }
        }).then((res) => {
          this.barData = res.data
          this.bar()
        })
      }
    },
    getPieData () {
      this.axios.get('/api/contract/project_type_count').then((res) => {
        this.pieData = res.data
        this.pie()
      })
    },
    getThreeData () {
      this.axios.get('/api/contract/project_three_year').then((res) => {
        this.three = res.data
        this.line()
      })
    },
    getYearData () {
      this.axios.get('/api/contract/contract_year_count', {
        params: {
          year: this.dateColorBar
        }
      }).then((res) => {
        this.con_year_conut = res.data
        this.colorLine()
      })
    },
    dateSelect1 () {
      this.dateToday1 = this.dateBar
      this.getBardata()
    },
    dateSelect2 () {
      this.dateToday2 = this.dateLine
      this.getThreeData()
    },
    dateSelect3 () {
      this.dateToday3 = this.dateColorBar
      this.getYearData()
    },
    getToday () {
      var today = new Date();
      var year = today.getFullYear();//获取年份
      var month = today.getMonth() + 1;//获取月份
      var day = today.getDate();//获取日期
      this.dateToday1 = year + '-' + month + '-' + day
      this.dateToday2 = year + '-' + month + '-' + day
      this.dateToday3 = year
    },
    monthClick (a) {
      this.newDate = a
      this.contractMonth()
    },
    // 本月合同
    contractMonth () {
      // const date = new Date()
      // this.newDate = date.getFullYear() + "-" + (date.getMonth() + 1);
      if (this.newDate) {
        this.axios.get('/api/contract/month_count', {
          params: {
            month: this.newDate
          }
        }).then((res) => {
          this.contractRunCount = +res.data.count
        })
      }
    },
    //进行中项目数量
    projectRuning () {
      this.axios.get('/api/contract/running_project').then((res) => {
        this.projectConut = +res.data.count
      })
    },
    // 总计合同数量
    contractNum () {
      this.axios.get('/api/contract/total_count').then((res) => {
        this.contractConut = +res.data.count
      })
    },
    // 意向客户数量
    customInt () {
      this.axios.get('/api/company/company_count', {
        params: {
          is_cooperation: "0",
        }
      }).then((res) => {
        this.intCount = +res.data.count
      })
    },
    // 合作客户数量
    customCoop () {
      this.axios.get('/api/company/company_count', {
        params: {
          is_cooperation: 1,
        }
      }).then((res) => {
        this.coopCount = +res.data.count
      })
    },
    // 柱状图函数
    bar () {



      var app = {};

      let chartDom = document.getElementById("mainbar");
      let myChart = this.$echarts.init(chartDom);
      let option;
      // 图表宽高自适应
      let listener = function () {
        myChart.resize();
      };
      EleResize.on(chartDom, listener);
      const posList = [
        "left",
        "right",
        "top",
        "bottom",
        "inside",
        "insideTop",
        "insideLeft",
        "insideRight",
        "insideBottom",
        "insideTopLeft",
        "insideTopRight",
        "insideBottomLeft",
        "insideBottomRight",
      ];
      app.configParameters = {
        rotate: {
          min: -90,
          max: 90,
        },
        align: {
          options: {
            left: "left",
            center: "center",
            right: "right",
          },
        },
        verticalAlign: {
          options: {
            top: "top",
            middle: "middle",
            bottom: "bottom",
          },
        },
        position: {
          options: posList.reduce(function (map, pos) {
            map[pos] = pos;
            return map;
          }, {}),
        },
        distance: {
          min: 0,
          max: 100,
        },
      };
      app.config = {
        rotate: 90,
        align: "left",
        verticalAlign: "middle",
        position: "insideBottom",
        onChange: function () {
          const labelOption = {
            rotate: app.config.rotate,
            align: app.config.align,
            verticalAlign: app.config.verticalAlign,
            position: app.config.position,
            distance: app.config.distance,
          };
          myChart.setOption({
            series: [
              {
                label: labelOption,
              },
              {
                label: labelOption,
              },
              {
                label: labelOption,
              },
              {
                label: labelOption,
              },
            ],
          });
        },
      };
      const labelOption = {
        show: true,
        position: app.config.position,
        distance: app.config.distance,
        align: app.config.align,
        verticalAlign: app.config.verticalAlign,
        rotate: app.config.rotate,
        formatter: "{c}  {name|{a}}",
        fontSize: 16,
        rich: {
          name: {},
        },
      };
      option = {
        title: {
          text: "客户统计",
          left: "4.5%",
          textStyle: {
            fontSize: 18,
            color: "#000",
            fontWeight: 700,
          },
        },
        // 显示数据
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          // 数据显示的背景色
          backgroundColor: "#fff",
          // 数据显示内的字体样式
          borderWidth: 1,
          borderColor: "#E7E9F2",
          padding: 20,
          textStyle: {
            color: "#000",
          },
        },
        legend: {
          itemWidth: 14,
          itemHeight: 14,
          // top: 8,
          left: "23%",
          data: [
            {
              name: this.barData.series[0].name,
            },
            {
              name: this.barData.series[1].name,
            },
            {
              name: this.barData.series[2].name,
            },
          ],
          textStyle: {
            color: "#A6ABC7",
            fontSize: 12,
          },
        },

        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: this.barData.abscissa,
            axisLine: {
              lineStyle: {
                color: "#A6ABC7",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            max: "200",
            min: "0",
            interval: 50,
            axisLabel: {
              formatter: "{value} 家",
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#A6ABC7",
              },
            },
          },
          // {
          //   type: "value",
          //   min: 0,
          //   max: 100,
          //   interval: 25,
          //   axisLabel: {
          //     formatter: "{value} 个",
          //   },
          //   axisLine: {
          //     show: false,
          //     lineStyle: {
          //       color: "#A6ABC7",
          //     },
          //   },
          // },
        ],
        series: [
          {
            name: this.barData.series[0].name,
            type: "bar",
            color: "#f48f19",
            barWidth: 12,
            emphasis: {
              focus: "series",
            },
            data: this.barData.series[0].data,
          },
          {
            name: this.barData.series[1].name,
            type: "bar",
            color: "#6652ff",
            barGap: "15%",
            barWidth: 12,
            emphasis: {
              focus: "series",
            },
            data: this.barData.series[1].data,
          },
          {
            name: this.barData.series[2].name,
            type: "bar",
            color: "#1fbaff",
            barWidth: 12,
            emphasis: {
              focus: "series",
            },
            data: this.barData.series[2].data,
          },
        ],
      };
      myChart.setOption(option);
    },
    // 饼图函数
    pie () {


      var chartDom = document.getElementById("mainpie");
      var myChart = this.$echarts.init(chartDom);
      var option;
      //图表宽高自适应
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      option = {
        title: {
          text: "总项目数据",
          top: 20,
          left: 0,
          textStyle: {
            color: "#000",
            fontSize: 18,
            fontWeight: "bold",
          },
        },
        tooltip: {
          trigger: "item",
          type: "axis",
          // 数据显示的背景色
          backgroundColor: "#fff",
          borderWidth: 1,
          borderColor: "#eee",
          // 数据显示内的字体样式
          textStyle: {
            color: "#000",
          },
        },
        legend: {
          itemWidth: 14,
          itemHeight: 14,
          bottom: 0,
          left: "center",
          itemGap: 20,
          textStyle: {
            color: "#A6ABC7",
            fontSize: 12,
          },
        },
        series: [
          {
            type: "pie",
            radius: ["35%", "65%"],
            avoidLabelOverlap: false,
            top: -20,
            label: {
              show: false,
              position: "center",
            },
            // borderWidh: 5,
            // hoverAnimation: true,
            data: this.pieData,
            color: [
              "#061278",
              "#10249e",
              "#1E39C3",
              "#3054EB",
              "#597EF7",
              "#85A5FF",
              "#ADC6FF",
              "#D6E4FF",
              "#F0F5FF",
            ],
          },
        ],
      };
      myChart.setOption(option);
    },
    //折线图函数
    line () {


      let chartDom = document.getElementById("mainline");
      let myChart = this.$echarts.init(chartDom);
      let option;
      // 图表宽高自适应
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      option = {
        // title: {
        //   text: "近三年项目数据",
        //   top: 0
        // },
        tooltip: {
          trigger: "axis",
          // 数据显示的背景色
          backgroundColor: "#fff",
          // 数据显示内的字体样式
          borderWidth: 1,
          borderColor: "#eee",
          textStyle: {
            color: "#000",
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: "#A6ABC7",
            },
          },
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
        },
        yAxis: {
          axisLine: {
            show: false,
            lineStyle: {
              color: "#A6ABC7",
            },
          },
          type: "value",
          interval: 500,
          min: 0,
          max: 1000,
          // splitNumber:500,
        },
        grid: {
          top: "10%",
          left: "5%",
          right: "5%",
        },
        legend: {
        },
        series: [
          {
            name: this.three[0].name,
            type: "line",
            data: this.three[0].data,
            symbolSize: 0,
            lineStyle: {
              width: 3,
            },
          },
          {
            name: this.three[1].name,
            type: "line",
            data: this.three[1].data,
            symbolSize: 0,
            lineStyle: {
              width: 3,
            },
          },
          {
            name: this.three[2].name,
            type: "line",
            data: this.three[2].data,
            symbolSize: 0,
            lineStyle: {
              width: 3,
            },
          },
        ],
        color: ["#FF1717", "#1063FF", "#02BEB6"],
      };
      myChart.setOption(option);
    },
    //渐变橙色折线图
    colorLine () {


      var chartDom = document.getElementById("maincolorline");
      var myChart = this.$echarts.init(chartDom);
      var option;
      // 图表宽高自适应
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      option = {
        // title: {
        //   text: '合同数据统计',
        //   top: 20,
        //   left: 0,
        //   textStyle: {
        //     color: '#000',
        //     fontSize: 18,
        //     fontWeight: 'bold'
        //   }
        // },

        tooltip: {
          // 数据显示的背景色
          backgroundColor: "#fff",
          borderWidth: 1,
          borderColor: "#eee",
          // 数据显示内的字体样式
          textStyle: {
            color: "#000",
          },
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter:
            '<div style="width:10px;height:10px;background:#0F38FF;display:inline-block;margin:0 6px 0;border-radius:50%;"></div>{b}月份新增合同   <b style="margin-left:52px;">{c} 份</b>'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            interval: 50,
            axisLabel: {
              formatter: '{value} 份'
            }
          }
        ],
        series: [
          {
            color: '#0F38FF',
            name: 'Direct',
            type: 'bar',
            barWidth: 18,
            data: this.con_year_conut
          }
        ]
      };

      // option = {
      //   // title: {
      //   //   text: 'Stacked Area Chart'
      //   // },
      //   tooltip: {
      //     trigger: "axis",
      //     // 移入图内显示横向信息
      //     // axisPointer: {
      //     //   type: 'cross',
      //     // },
      //     // 数据显示的背景色
      //     backgroundColor: "#fff",
      //     borderWidth: 1,
      //     borderColor: "#eee",
      //     // 数据显示内的字体样式
      //     textStyle: {
      //       color: "#000",
      //     },

      //     formatter:
      //       '<div style="width:10px;height:10px;background:orange;display:inline-block;margin:0 6px 0;border-radius:50%;"></div>{b}份收款   <b style="margin-left:52px;">{c}万元</b>',
      //   },
      //   grid: {
      //     left: "3%",
      //     right: "4%",
      //     bottom: "3%",
      //     containLabel: true,
      //   },
      //   xAxis: [
      //     {
      //       type: "category",
      //       // boundaryGap: false,
      //       data: [
      //         "1月",
      //         "2月",
      //         "3月",
      //         "4月",
      //         "5月",
      //         "6月",
      //         "7月",
      //         "8月",
      //         "9月",
      //         "10月",
      //         "11月",
      //         "12月",
      //       ],
      //       axisLine: {
      //         lineStyle: {
      //           color: "#A6ABC7",
      //         },
      //       },
      //     },
      //   ],
      //   yAxis: [
      //     {
      //       axisLine: {
      //         show: false,
      //         lineStyle: {
      //           color: "#A6ABC7",
      //         },
      //       },
      //       type: "value",
      //       min: 0,
      //       interval: 50,
      //       axisLabel: {
      //         formatter: "{value} 万",
      //       },
      //       splitLine: {
      //         show: false,
      //       },
      //     },
      //   ],
      //   series: [
      //     {
      //       showSymbol: false,
      //       symbolSize: 5,
      //       type: "line",
      //       // stack: 'Total',
      //       symbol: false,
      //       // color: "#F48F19",
      //       itemStyle: {
      //         normal: {
      //           color: "#F48F19",
      //           borderWidth: 2,
      //           borderColor: "#fff",
      //         },
      //       },
      //       lineStyle: {
      //         width: 3,
      //       },
      //       // 射线图弯曲程度
      //       smooth: 0.5,
      //       emphasis: {
      //         // focus: 'series'
      //       },
      //       data: [60, 70, 85, 80, 65, 70, 75, 63, 77, 59, 50, 57],
      //       areaStyle: {
      //         normal: {
      //           color: new this.$echarts.graphic.LinearGradient(
      //             0,
      //             0,
      //             0,
      //             1,
      //             [
      //               {
      //                 offset: 0,
      //                 color: "#FFCC91",
      //               },
      //               {
      //                 offset: 1,
      //                 color: "#FFFBF7",
      //               },
      //             ],
      //             false
      //           ),
      //         },
      //       },
      //     },
      //   ],
      // };
      myChart.setOption(option);
    },
    onCustom () {
      this.show = !this.show;
    },
    checkOnes () {
      this.checkOne = !this.checkOne;
      // console.log("变化后的值", this.checkOne);
    },
    checkTwos () {
      this.checkTwo = !this.checkTwo;
    },
    checkThrees () {
      this.checkThree = !this.checkThree;
    },
    checkFours () {
      this.checkFour = !this.checkFour;
    },
  },
  mounted () {
    //全局缩放影响echarts容器，焦点偏移
    this.zoom = 1 / document.body.style.zoom;
    window.addEventListener("resize", () => {
      this.zoom = 1 / document.body.style.zoom;
    });


    const date = new Date()
    this.newDate = date.getFullYear() + "-" + (date.getMonth() + 1);
    this.newDay = date.getFullYear() + "-" + (date.getMonth() + 1) + '-' + (date.getDate());
    this.yearNow = date.getFullYear()
    this.$nextTick(() => {
      this.bar();
    });
    this.$nextTick(() => {
      if (this.pieData) {
        this.pie();
      }
    });
    this.$nextTick(() => {
      if (this.three) {
        this.line();
      }
    });
    this.$nextTick(() => {
      if (this.con_year_conut) {
        this.colorLine();
      }
    });
    // this.price1 = this.textformat(23445300, "#,##0");
  },
};
</script>

<style scoped>
.shuj {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .shuj h1 {
  font-size: 20px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #000000;
  line-height: 14px;
}
.indexicon {
  display: flex;
  align-items: center;
}
.card {
  width: 100%;
  display: flex;
  margin: 20px 0;
  justify-content: space-between;
}
.card .el-row {
  width: 20%;
}
.card .el-card {
  margin-right: 20px;
  height: 120px;
}
.card span {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #a6abc7;
}
.card span h4 {
  margin: 0 8px 0 23px;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #a6abc7;
  line-height: 14px;
}
.custom .card h1 {
  font-size: 31px;
  font-family: pangmen;
  font-weight: 400;
  color: #141414;
}
.card h1 span {
  display: inline-block;
}
.customs {
  display: block;
  box-shadow: 0px 0px 10px 0px #edeff5;
  border-radius: 4px;
  background: linear-gradient(316deg, #488bff, #2e56ff 87%);
}
.custom {
  width: 100%;
  height: 100%;
  padding: 1px 25px;
  box-sizing: border-box;
  cursor: pointer;
}
.custom h1 {
  writing-mode: horizontal-tb;
  font-size: 22px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #ffffff;
  margin-top: 15px;
}
.card .custom h2 {
  opacity: 0.38;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #ffffff;
  letter-spacing: 0.98px;
}
.card .custom .h3 {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  z-index: 30;
}
.card .skyblue,
.blue {
  width: 60%;
  height: 60%;
  border-radius: 50%;
  position: absolute;
}
.card .custom .hidden .skyblue {
  background: #46dbff;
  z-index: 10;
  top: 50%;
  left: 70%;
}
.card .custom .hidden .blue {
  background: #2e6eff;
  z-index: 20;
  top: 60%;
  left: 60%;
}
.card .custom .hidden {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 10px;
  overflow: hidden;
}
.el-checkbox {
  display: block;
  margin: 10px 0 10px 20px;
}
.mainbar {
  width: 53.5%;
  background: #fff;
  position: relative;
}
.mainpie,
.maincolorline {
  width: 45%;
  height: 100%;
  background: #fff;
}
.allech {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.allech span {
  margin: 0 13px;
  display: inline-block;
}
.allech img {
  margin-left: 6px;
}
#mainbar {
  width: 100%;
  height: 90%;
  margin: 20px auto;
}
#mainpie {
  width: 90%;
  height: 378px;
  margin: 0 auto;
  margin-bottom: 20px;
}
#maincolorline {
  width: 90%;
  height: 230px;
  margin: -10px auto 0;
}
#mainline {
  width: 90%;
  height: 100%;
  margin: 30px auto 0;
}
.mainline {
  width: 53.5%;
  height: 276px;
  margin-top: 20px;
  position: relative;
  background: #fff;
}
.mainline h1 {
  font-size: 18px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #000000;
  position: absolute;
  top: 20px;
  left: 40px;
}
.maincolorline h1 {
  font-size: 18px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #000000;
  position: absolute;
  top: 20px;
  left: 40px;
}
.maincolorline {
  height: 276px;
  position: relative;
  margin-top: 20px;
}
.bartext,
.linetext,
.colorlinetext {
  position: absolute;
  top: 22px;
  right: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 999;
}
.bartext,
.linetext,
.colorlinetext span {
  font-size: 12px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #a6abc7;
  cursor: pointer;
  z-index: 10;
}
.date_select {
  width: 100px;
}
.dateBar {
  width: 100px !important;
}
div/deep/.date_select .el-input__inner {
  border: none;
  cursor: pointer;
  padding: 0;
  width: 100px;
  color: #a6abc7;
}
div/deep/.dateBar .el-input__prefix {
  width: 100px;
  text-align: right;
}
div/deep/.dateBar .el-input__icon {
  width: 100px;
  text-align: right;
}
</style>
