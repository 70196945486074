export const provinces = {
    台湾: 'taiwan',
    河北: 'hebei',
    山西: 'shanxi',
    辽宁: 'liaoning',
    吉林: 'jilin',
    黑龙江: 'heilongjiang',
    江苏: 'jiangsu',
    浙江: 'zhejiang',
    安徽: 'anhui',
    福建: 'fujian',
    江西: 'jiangxi',
    山东: 'shandong',
    河南: 'henan',
    湖北: 'hubei',
    湖南: 'hunan',
    广东: 'guangdong',
    海南: 'hainan',
    四川: 'sichuan',
    贵州: 'guizhou',
    云南: 'yunnan',
    陕西: 'shanxi1',
    甘肃: 'gansu',
    青海: 'qinghai',
    新疆: 'xinjiang',
    广西: 'guangxi',
    内蒙古: 'neimenggu',
    宁夏: 'ningxia',
    西藏: 'xizang',
    北京: 'beijing',
    天津: 'tianjin',
    上海: 'shanghai',
    重庆: 'chongqing',
    香港: 'xianggang',
    澳门: 'aomen'
}